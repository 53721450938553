require('./bootstrap');
window.Vue = require('vue');
import axios from 'axios';

Vue.component('drag-template', require('./components/DragTemplate.vue').default);
Vue.component('header-template', require('./components/HeaderTemplate.vue').default);

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    Pace.restart();
    return config;
}, function (error) {
    return Promise.reject(error);
});

Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};
export const busReset = function () {
    return {
        nextList: [],
        oldList: [],
        allLists: {},
        movedEl: {},
        indexToRemove: -1,
        oldIndex: -1
    }
}
export const bus = new Vue({
    data() {
        return busReset()
    }
});

const app = new Vue({
    el: '#app',
});
