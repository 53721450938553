<template>
    <div class="col align-self-center">
        <label for="blocoHeader">Selecione um dos itens abaixo</label>
        <select id="blocoHeader" class="form-control" v-model="localModId"
                @change="atualizarPosicoes($event)"
        >
            <option
                v-for="(element, index) in listDat"
                :key="index"
                :value="element.id">
                {{ element.nome }}
            </option>
        </select>
    </div>
</template>
<script>
import {bus, busReset} from '../app';

export default {
    name: "header-template",

    props: ['listDat', 'currentGroup', 'currentIndex', 'clienteModId'],
    data() {
        return {
            localModId: null,
            localList: [],
            selected: {},
        };
    },
    methods: {
        atualizarPosicoes(ev) {
            axios.post(`/admin/clienteTemplate/updateModuloHeader/${this.currentIndex}/${this.localModId}`, {}).then((dat) => {
                let resp = dat.data.data;
                if (resp.ok) {
                    showToastr(true, "Template Atualizado com sucesso");
                } else {
                    showToastr(false, "Falha ao atualizar o Template");
                }
            });
        }
    }, mounted() {
        this.localList = this.listDat;
        this.localModId = this.clienteModId;
        // console.log(this.localList);
        this.localList.map(obj => {
            obj['currentIndex'] = this.currentIndex;
        })

    }
};
</script>

<style lang="scss">
.component-draggable {
    .list-group-item {
        cursor: pointer;
    }
}
</style>
