<template>
    <div class="component-draggable">
        <draggable class="list-group" :list="localList"
                   :move="checkMove"
                   :group="currentGroup"
                   @change="log">
            <div
                class="list-group-item"
                v-for="(element, index) in localList"
                :key="index"
            >
                <div v-if="!element.modulable">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fa fa-box-open"></i>&nbsp;</span>
                        </div>
                        <input type="text" class="form-control" :value="element.titulo"
                               v-on:blur="alterTitle($event,element)" v-on:keyup.enter="$event.target.blur()"
                        >
                    </div>
                </div>
                <div v-if="element.modulable">
                    <i class="fa fa-box-open"></i>&nbsp;{{ element.modulable.nome }}
                    <small class="text-muted">({{ element.modulo.nome }})</small>
                </div>
                <div class="custom-control custom-switch float-right ml-2">
                    <input type="checkbox" style="cursor: pointer"
                           @change="alterarVisivel(element.id,element)"
                           :checked="element.visivel ? 'checked' : ''" class="custom-control-input"
                           :id="'customSwitch'+ element.id">
                    <label class="custom-control-label" :for="'customSwitch'+ element.id">
                        {{ element.visivel ? 'Ativado' : 'Desativado' }}
                    </label>
                </div>
                <div class="custom-control custom-switch float-right ml-2">
                    <input type="checkbox" style="cursor: pointer"
                           @change="alterarVisivel(element.id,element,'alterarVisivel','esconder_titulo')"
                           :checked="element.esconder_titulo ? 'checked' : ''" class="custom-control-input"
                           :id="'customSwitchEsc'+ element.id">
                    <label class="custom-control-label" :for="'customSwitchEsc'+ element.id">
                        {{ element.esconder_titulo ? 'Mostrar Título' : 'Esconder Título' }}
                    </label>
                </div>

                <div v-if="(element.modulo.table?element.modulo.table: '').toLowerCase() === 'videos'">
                    <div class="custom-control custom-switch float-right">
                        <input type="checkbox" style="cursor: pointer"
                               @change="alterarGrid(element.id,element)"
                               :checked="element.grid ? 'checked' : ''" class="custom-control-input"
                               :id="'customVideo'+ element.id">
                        <label class="custom-control-label" :for="'customVideo'+ element.id">
                            {{ element.grid ? 'Grid' : 'Slide' }}
                        </label>
                    </div>
                </div>
            </div>
        </draggable>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import {bus, busReset} from '../app';

export default {
    name: "drag-template",
    components: {
        draggable
    },
    props: ['listDat', 'currentGroup', 'currentIndex', 'currentTemplate'],
    data() {
        return {
            localList: []
        };
    },
    methods: {
        alterarVisivel: function (id, element, uri = 'alterarVisivel', attr = 'visivel') {
            axios.post(`/admin/clienteTemplate/${uri}/${id}`, {
                'attr': attr
            }).then((dat) => {
                let resp = dat.data.data;
                if (resp.ok) {
                    element[attr] = !element[attr];
                    showToastr(true, "Template Atualizado com sucesso");
                } else {
                    showToastr(false, "Falha ao atualizar o Template");
                }
            });
        }, alterarGrid: function (id, element) {
            let group = '';
            if (this.currentGroup.trim() === 'listL') group = 'listR';
            if (this.currentGroup.trim() === 'listR') group = 'listL';

            axios.post(`/admin/clienteTemplate/alterarGrid/${id}`, {}).then((dat) => {
                let resp = dat.data.data;
                if (resp.ok) {
                    element.grid = !element.grid;
                    if (this.currentTemplate === 'default') {
                        let idx = bus.allLists[this.currentGroup].indexOf(element)
                        bus.allLists[group].splice(idx, 0, element)
                        this.localList.splice(idx, 1);
                    }
                    showToastr(true, "Template Atualizado com sucesso");
                } else {
                    showToastr(false, "Falha ao atualizar o Template");
                }
            });
        },
        alterTitle: function (e, el) {
            let newTitle = e.target.value;
            let oldTitle = el.titulo;
            axios.post(`/admin/clienteTemplate/alterarTitulo/${el.id}`, {
                'newTitle': newTitle,
                'oldTitle': oldTitle
            }).then((dat) => {
                let resp = dat.data.data;
                if (resp.ok) {
                    if (resp.resetTitle) {
                        e.target.value = resp.resetTitle;
                    }
                    showToastr(true, "Template Atualizado com sucesso");
                } else {
                    e.target.value = oldTitle;
                    showToastr(false, "Falha ao atualizar o Template");
                }
            });

        },
        checkMove: function (evt) {

            let indexList = evt.relatedContext.list[0].currentIndex;
            let newIndex = evt.draggedContext.futureIndex;
            let oldIndex = evt.draggedContext.index;

            evt.draggedContext.element.currentIndex = indexList;

            let indexToRemove = (newIndex + 1);
            if (!evt.relatedContext.list[newIndex]) {
                indexToRemove = newIndex - 1;
            }
            bus.$data.movedEl = evt.draggedContext.element;
            bus.$data.indexToRemove = indexToRemove;
            bus.$data.nextList = evt.relatedContext.list;
            bus.$data.oldList = this.localList;
            bus.$data.oldIndex = oldIndex;
        },
        log: function (evt) {
            // console.log(evt);
            if (evt.added) {
                let idx = bus.$data.indexToRemove;
                let oldIndex = bus.$data.oldIndex;
                if (idx <= -1) return;
                let newEl = bus.$data.nextList[idx];
                newEl.currentIndex = this.currentIndex;
                bus.$data.oldList.insert(oldIndex, newEl);
                this.atualizarPosicoes(bus.$data.movedEl, newEl);
                bus.$data.nextList.splice(idx, 1);
                bus.$data = busReset();
            }
            if (evt.moved) {
                // console.log(evt.moved);console.log(this.localList[evt.moved.oldIndex]);console.log(this.localList[evt.moved.newIndex]);
                let oldEl = this.localList[evt.moved.oldIndex];
                let newEl = this.localList[evt.moved.newIndex];
                this.atualizarPosicoes(oldEl, newEl);

            }
        },
        atualizarPosicoes(oldEl, newEl) {
            axios.post(`/admin/clienteTemplate/updateModuloDinamico/${oldEl.id}/${newEl.id}`, {}).then((dat) => {
                let resp = dat.data.data;
                if (resp.ok) {
                    showToastr(true, "Template Atualizado com sucesso");
                } else {
                    showToastr(false, "Falha ao atualizar o Template");
                }
            });
        }
    }, mounted() {
        console.log(this.currentTemplate)
        this.localList = this.listDat;
        this.localList.map(obj => {
            obj['currentIndex'] = this.currentIndex;
        })
        bus.allLists[this.currentGroup] = this.localList
    }
};
</script>

<style lang="scss">
.component-draggable {
    .list-group-item {
        cursor: pointer;
    }
}
</style>
